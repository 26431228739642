import { faLink } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ButtonGroup, Container } from './PollInviteByLink.styles';

interface PollInviteByLinkProps {
  onCopy: () => void;
  onDone: () => void;
}

export function PollInviteByLink({ onCopy, onDone }: PollInviteByLinkProps) {
  const mobile = useIsMobileViewport();

  return (
    <Container>
      <RMText type="sans" size="s" color="on-surface-primary" bold>
        Invite via link
      </RMText>
      <RMSpacer direction="column" spacing="xs" />
      <RMText type="sans" size="s" color="on-surface-primary">
        Anyone can use this link to vote on this poll
      </RMText>
      <RMSpacer direction="column" spacing="xl" />
      <ButtonGroup isMobile={mobile}>
        <RMButton id="invite-dialog-copy-link-button" leftIcon={faLink} onClick={onCopy} autoLoading>
          Copy link
        </RMButton>
        {!mobile && (
          <RMButton id="invite-dialog-done-button" onClick={onDone} background="primary">
            Done
          </RMButton>
        )}
      </ButtonGroup>
    </Container>
  );
}
