import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

function formatTimer(expireOn: number) {
  const remainingTime = Math.max(expireOn - Date.now(), 0);

  const duration = dayjs.duration(remainingTime, 'milliseconds');
  const hours = String(duration.hours()).padStart(2, '0') + 'h';
  const minutes = String(duration.minutes()).padStart(2, '0') + 'm';
  const seconds = String(duration.seconds()).padStart(2, '0') + 's';

  return {
    hours,
    minutes,
    seconds,
    full: [hours, minutes, seconds].join(', '),
  };
}

export function usePollExpirationTimer(expireOn: number) {
  const [timer, setTimer] = useState(() => formatTimer(expireOn));

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = Math.max(expireOn - Date.now(), 0);
      if (remainingTime <= 0) {
        clearInterval(interval);
      }
      setTimer(formatTimer(expireOn));
    }, 1000);

    return () => clearInterval(interval);
  }, [expireOn]);

  return timer;
}
