import { useCallback } from 'react';

import { useServices } from '@/Services.js';

import { PollShareDialog } from '../components/PollShareDialog/PollShareDialog';

import { PollInviteByEmailContainer } from './PollInviteByEmail.container';
import { PollInviteByLinkContainer } from './PollInviteByLink.container';
import { PollShareMemberListContainer } from './PollShareMemberList.container';

const intercomHelpUrl =
  'https://intercom.help/remento/en/articles/8365949-how-do-i-invite-collaborators-to-add-prompts-for-the-recipient';

export interface PollShareDialogContainerProps {
  projectId: string;
  pollId: string;
  open: boolean;
  onClose: () => void;
}

export function PollShareDialogContainer({ projectId, pollId, open, onClose }: PollShareDialogContainerProps) {
  const { collaborationAnalyticsService } = useServices();

  const handleOpenHelp = useCallback(() => {
    window.open(intercomHelpUrl, '_blank');
    collaborationAnalyticsService.onHelpPressed();
  }, [collaborationAnalyticsService]);

  return (
    <PollShareDialog
      open={open}
      onOpenHelp={handleOpenHelp}
      onClose={onClose}
      InviteByEmail={<PollInviteByEmailContainer pollId={pollId} />}
      UserList={<PollShareMemberListContainer projectId={projectId} />}
      InviteByLink={<PollInviteByLinkContainer pollId={pollId} onClose={onClose} />}
    />
  );
}
