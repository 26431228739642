import { RMText } from '@/components/RMText/RMText';

import { PollOptionContent, PollOptionQuestion, PollOptionResult } from './PollVoteOption.styles';

interface PollVoteOptionProps {
  question: string;
  selected: boolean;
  showResults: boolean;
  voteRatio: number;
  onSelect: () => void;
}

export function PollVoteOption({ question, selected, showResults, voteRatio, onSelect }: PollVoteOptionProps) {
  return (
    <PollOptionContent>
      <PollOptionQuestion
        data-selectable={!showResults}
        data-selected={selected}
        onClick={() => !showResults && onSelect?.()}
      >
        {showResults && (
          <PollOptionResult
            key={String(showResults)}
            initial={{ width: '0%' }}
            animate={{ width: voteRatio * 100 + '%' }}
          />
        )}
        <RMText color={showResults ? 'on-surface-primary' : 'primary'} type="sans" size="s" bold>
          {question}
        </RMText>
      </PollOptionQuestion>
    </PollOptionContent>
  );
}
