import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const PollOptionContent = styled.div`
  width: 100%;
`;

export const PollOptionQuestion = styled.div`
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  background: var(--surface-bright);
  padding: var(--spacing-lg) var(--spacing-3xl) var(--spacing-lg) var(--spacing-lg);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-1);
  border-radius: var(--radius-round);
  position: relative;
  transition: background 150ms ease;
  overflow: hidden;

  &[data-selectable='true'] {
    cursor: pointer;
    &:hover {
      background-color: var(--darken-neutral-hover);
    }

    &:active {
      background-color: var(--darken-neutral-press);
    }

    &[data-selected='true'] {
      border: 1px solid transparent;
      outline: 3px solid var(--primary);
    }
  }
`;

export const PollOptionResult = styled(motion.div)`
  background: var(--darken-primary-press);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
