import { useProjectMembers } from '@/modules/sharing/hooks/useProjectMembers';

import { ProjectShareMemberList } from '../components/ProjectShareMemberList';

function getRoleLabel(type: 'host' | 'collaborator' | 'storyteller' | 'invited'): string {
  switch (type) {
    case 'host':
      return 'Owner';
    case 'collaborator':
    case 'invited':
      return 'Collaborator';
    case 'storyteller':
      return 'Storyteller';
    default:
      return 'Unknown';
  }
}

export interface PollShareMemberListContainerProps {
  projectId: string;
}

export function PollShareMemberListContainer({ projectId }: PollShareMemberListContainerProps) {
  const members = useProjectMembers(projectId);

  return (
    <ProjectShareMemberList.Root>
      {members?.map((member) => (
        <ProjectShareMemberList.Item
          key={member.email}
          initials={(member.type !== 'invited' ? member.initials : '') ?? ''}
          name={(member.type !== 'invited' ? member.name?.full : '') ?? ''}
          email={member.email}
          photoUrl={member.type !== 'invited' ? member.avatarUrl : null}
          displayLabel={getRoleLabel(member.type)}
          avatarColor={member.type !== 'invited' ? member.avatarColor : null}
          pending={member.type === 'invited'}
          readOnly
        />
      ))}
    </ProjectShareMemberList.Root>
  );
}
