import { useCallback, useMemo } from 'react';
import { ConflictError, ConflictErrorType } from '@remento/types/error';

import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { usePollQuery } from '@/services/api/poll/poll.service.hook';
import { captureException } from '@/utils/captureException';

import { submitForm } from '../../form/form';
import { ProjectInviteByEmail } from '../components/ProjectInviteByEmail/ProjectInviteByEmail';
import { createPersonInviteByEmailForm } from '../form/person-invite-by-email.form';

export interface PollInviteByEmailContainerProps {
  pollId: string;
}

export function PollInviteByEmailContainer({ pollId }: PollInviteByEmailContainerProps) {
  // Services
  const { collaborationAnalyticsService, pollCacheService } = useServices();

  // State
  const form = useMemo(() => createPersonInviteByEmailForm(), []);

  // Queries
  const pollQuery = usePollQuery(pollId);

  const handleSubmit = useCallback(async () => {
    if (!pollQuery.data) {
      logger.warn('POLL_NOT_LOADED', { pollId });
      return;
    }

    await submitForm(form, async (formValue) => {
      try {
        collaborationAnalyticsService.onInvitedViaEmail(1, 'poll-dialog');

        await pollCacheService.sendEmailInvite(pollId, formValue.email);
        toast('Invite sent');
        setInputValue(form, 'email', '');
      } catch (error) {
        if (error instanceof ConflictError && error.data?.type === ConflictErrorType.EMAIL_ALREADY_PART_OF_ACL_GROUP) {
          toast('This email is already associated with a collaborator on the account.', 'root-toast', 'error');
          return;
        }
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [pollCacheService, collaborationAnalyticsService, form, pollId, pollQuery.data]);

  return <ProjectInviteByEmail form={form} onSubmit={handleSubmit} />;
}
