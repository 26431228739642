import { Person } from '@remento/types/person';
import { PollVote } from '@remento/types/poll-vote';
import { User } from '@remento/types/user';
import { notNull } from '@remento/utils/array/notNull';

import { AnonymousVote } from '@/services/api/poll';

export function formatPollVoteCount(
  votes: PollVote[],
  people: Person[],
  currentUser: User | null,
  anonymousVote?: AnonymousVote | null,
): string {
  if (votes.length == 0) {
    return '';
  }

  const lf = new Intl.ListFormat('en');

  const namesLimit = 3;
  const knownNames = votes
    .map((vote) => {
      const { personId } = vote;
      if ((currentUser != null && currentUser.personId == personId) || anonymousVote?.voteId === vote.id) {
        return 'You';
      }

      if (personId == null) {
        return null;
      }

      const person = people.find((p) => p.refIds.includes(personId));
      if (person?.name != null) {
        return person.name.first;
      }
      return null;
    })
    .filter(notNull)
    .sort((a, b) => (a === 'You' ? -1 : b === 'You' ? 1 : 0))
    .filter((_, i) => i < namesLimit);

  const anonymousVotes = votes.length - knownNames.length;
  if (anonymousVotes > 0) {
    return `${lf.format([...knownNames, String(anonymousVotes)])} ${anonymousVotes > 1 ? 'others' : 'other'} voted`;
  }

  return `${lf.format(knownNames)} voted`;
}
