import { ReactNode } from 'react';

import { RMText } from '@/components/RMText/RMText';

import { AvatarListRoot, StyledPollVoteAvatarList } from './PollVoteAvatarList.styles';

export interface PollVoteAvatarListProps {
  ListItems: ReactNode;
  votesDescription: string;
}

export function PollVoteAvatarList({ ListItems, votesDescription }: PollVoteAvatarListProps) {
  return (
    <StyledPollVoteAvatarList>
      <AvatarListRoot>{ListItems}</AvatarListRoot>
      {votesDescription.length > 0 && (
        <RMText align="center" type="sans" size="xs" color="on-surface-primary">
          {votesDescription}
        </RMText>
      )}
    </StyledPollVoteAvatarList>
  );
}
