import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const SelectionContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SelectionItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  body[data-mobile='true'] & {
    justify-content: center;
  }
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20.5rem;
  gap: var(--spacing-xs);
`;

export const OptionDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.25rem;
`;

export const PollPromptInfo = styled.div`
  width: 100%;
  margin-top: var(--spacing-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-child {
    margin-left: auto;
  }
`;

export const PersonNames = styled.div`
  width: 100%;
`;

export const CheckVote = styled(FontAwesomeIcon)`
  margin-right: var(--spacing-xs);
`;
