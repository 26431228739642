import { useMemo } from 'react';
import { notNull } from '@remento/utils/array/notNull';
import Crc32 from 'crc-32';

import userImageSrc from '@/assets/user.svg';
import { RMAvatarSharing } from '@/components/RMAvatarSharing';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople, usePersonAvatarUrl, usePersonInitials, usePersonQuery } from '@/services/api/person';
import { usePollVotes, usePollVotesQuery } from '@/services/api/poll';
import { Colors } from '@/styles/base/colors';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { PollVoteAvatarList } from '../components/PollVoteAvatarList/PollVoteAvatarList';
import { formatPollVoteCount } from '../utils/poll-vote.utils';

interface PollVoteAvatarListItemContainer {
  personId: string | null;
}

function PollVoteAvatarListItemContainer({ personId }: PollVoteAvatarListItemContainer) {
  const personQuery = usePersonQuery(personId);
  const initials = usePersonInitials(personQuery.data);
  const avatarUrl = usePersonAvatarUrl(personQuery.data);

  const color = useMemo(() => {
    if (personId == null) {
      return ColorsTokens['gray-avatar'];
    }

    const colors = [Colors.yellow[30], Colors.aqua[70], Colors.red[80], Colors.red[40], Colors.blend[69]];
    const hash = Crc32.str(personId) >>> 0;
    return colors[hash % colors.length];
  }, [personId]);

  return (
    <RMAvatarSharing.Item
      size="small"
      border="surface"
      imageURL={personId ? avatarUrl : userImageSrc}
      text={initials ?? ''}
      backgroundColor={color}
      overlapping={true}
      tooltip={personQuery.data?.name?.full}
    />
  );
}

export interface PollVoteAvatarListContainerProps {
  pollId: string | null;
}

const MAX_AVATARS = 4;

export function PollVoteAvatarListContainer({ pollId }: PollVoteAvatarListContainerProps) {
  const user = useUser();

  const pollVotesQuery = usePollVotesQuery(pollId);
  const pollVotes = usePollVotes(pollVotesQuery.data ?? null);
  const people = usePeople(useMemo(() => pollVotes.map((v) => v.personId).filter(notNull), [pollVotes]));

  const avatarShowLimit = pollVotes.length > MAX_AVATARS ? MAX_AVATARS - 1 : MAX_AVATARS;
  const voteExceeded = pollVotes.length - avatarShowLimit;

  const ListItems = useMemo(
    () =>
      pollVotes
        .sort((a, b) => {
          if (a.personId == null && b.personId == null) {
            return 0;
          }

          if (a.personId === null) {
            return 1;
          }

          return -1;
        })
        .slice(0, avatarShowLimit)
        .map((vote) => <PollVoteAvatarListItemContainer key={vote.id} personId={vote.personId} />),
    [avatarShowLimit, pollVotes],
  );

  return (
    <PollVoteAvatarList
      votesDescription={formatPollVoteCount(pollVotes, people, user ?? null)}
      ListItems={
        <>
          {ListItems}
          {voteExceeded > 0 && (
            <RMAvatarSharing.Item
              size="small"
              border="surface"
              text={`+${voteExceeded}`}
              backgroundColor={ColorsTokens['on-secondary']}
              overlapping={true}
            />
          )}
        </>
      }
    />
  );
}
