import { useCallback, useEffect, useMemo, useState } from 'react';
import { notNull } from '@remento/utils/array/notNull';

import { logger } from '@/logger';
import { formatPollVoteCount } from '@/modules/poll/utils/poll-vote.utils';
import { useAvailablePromptTemplates } from '@/modules/prompt/hooks/available-prompt.hook';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople } from '@/services/api/person';
import { usePollQuery, usePollVotes, usePollVotesQuery } from '@/services/api/poll';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';

export interface PollOption {
  templateId: string;
  tagId: string;
  question: string;
  customized: boolean;
}

export function usePollOptions(projectId: string) {
  const { pollAnalyticsService } = useServices();

  // State
  const [availablePollOptions, setAvailablePollOptions] = useState<PromptTemplate[]>([]);
  const [pollOptions, setPollOptions] = useState<PollOption[]>([]);
  const [shuffling, setShuffling] = useState(false);
  const unusedTemplates = useAvailablePromptTemplates(projectId, {
    templateTag: 'polls',
    minimumNumberOfResults: 2,
  });

  const setRandomOptions = useCallback((options: PromptTemplate[]) => {
    if (options.length < 2) {
      return;
    }

    let random = Math.floor(Math.random() * options.length);
    const question1 = options.splice(random, 1)[0];

    random = Math.floor(Math.random() * options.length);
    const question2 = options.splice(random, 1)[0];

    const mapTemplateToPollOption = ({ id, tags, questions }: PromptTemplate) => ({
      customized: false,
      question: questions[0].text,
      tagId: tags.find((tag) => tag.value === 'polls')?.id ?? '',
      templateId: id,
    });

    setPollOptions([mapTemplateToPollOption(question1), mapTemplateToPollOption(question2)]);
    setAvailablePollOptions(options);
  }, []);

  const shuffle = useCallback(async () => {
    if (unusedTemplates == null) {
      logger.warn('TEMPLATES_STILL_LOADING');
      return;
    }

    pollAnalyticsService.onPollQuestionsShuffled();

    if (availablePollOptions.length < 2) {
      setAvailablePollOptions([...unusedTemplates]);
    }

    // start animation
    setShuffling(true);

    setTimeout(() => {
      // set new options in the middle of animation
      setRandomOptions(availablePollOptions);

      setTimeout(() => {
        // finish animation
        setShuffling(false);
      }, 1250);
    }, 750);
  }, [availablePollOptions, pollAnalyticsService, setRandomOptions, unusedTemplates]);

  useEffect(() => {
    if (unusedTemplates != null) {
      setAvailablePollOptions([...unusedTemplates]);
      setRandomOptions([...unusedTemplates]);
    }
  }, [unusedTemplates, setRandomOptions]);

  return {
    pollOptions,
    setPollOptions,
    shuffling,
    shuffle,
  };
}
export interface PollVotingOption {
  id: string;
  question: string;
  voteRatio: number;
  voteRatioDescription: string;
  voted: boolean;
}

export function usePollVotingOptions(pollId: string | null): PollVotingOption[] | null {
  const { pollCacheService } = useServices();
  const user = useUser();
  const pollQuery = usePollQuery(pollId);
  const pollVotesQuery = usePollVotesQuery(pollId);

  const pollVotes = usePollVotes(pollVotesQuery.data ?? null);
  const people = usePeople(useMemo(() => pollVotes.map((v) => v.personId).filter(notNull), [pollVotes]));
  const anonymousVote = pollCacheService.getAnonymousVote(pollId);

  return useMemo<PollVotingOption[] | null>(() => {
    if (pollQuery.data == null || pollVotesQuery.data == null) {
      return null;
    }

    return pollQuery.data.prompts.map((prompt) => {
      const promptVotes = pollVotes.filter((v) => v.promptId === prompt.id);

      return {
        id: prompt.id,
        question: prompt.text,
        // This might break with the standalone view with anonymous users.
        // We need to check the anonymous repository as well.
        voted: pollVotes.some(
          (v) => v.promptId == prompt.id && (v.personId == user?.personId || anonymousVote?.voteId === v.id),
        ),
        voteRatio: pollVotes.length ? promptVotes.length / pollVotes.length : 0,
        voteRatioDescription: formatPollVoteCount(promptVotes, people, user ?? null, anonymousVote),
      };
    });
  }, [anonymousVote, people, pollQuery.data, pollVotes, pollVotesQuery.data, user]);
}
