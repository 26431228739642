import { Fragment, ReactNode, useMemo } from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { PersonName } from '@remento/types/person';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { PollVotingOption } from '../../hooks/poll-options.hook';
import { PollVoteOption } from '../PollVoteOption/PollVoteOption';

import {
  CheckVote,
  DescriptionContent,
  OptionDivider,
  PersonNames,
  PollPromptInfo,
  SelectionContent,
  SelectionItems,
} from './PollVotingSelection.styles';

export interface PollVotingSelectionProps {
  state: 'vote' | 'results';
  subjectName: PersonName | null;
  options: PollVotingOption[];
  selectedOptionId: string | null;
  onSelect: (option: PollVotingOption) => void;
  PollVotesList: ReactNode;
}

export function PollVotingSelection({
  state,
  subjectName,
  options,
  selectedOptionId,
  onSelect,
  PollVotesList,
}: PollVotingSelectionProps) {
  const containVotes = useMemo(() => options.some((o) => o.voteRatio > 0), [options]);

  return (
    <SelectionContent>
      <RMSpacer spacing="xl" direction="column" />

      <DescriptionContent>
        <RMText align="center" type="serif" size="m" color="on-surface-primary">
          {state === 'vote' ? `What should ${subjectName?.first ?? 'Someone'} answer next?` : 'Your vote is counted!'}
        </RMText>
        <RMText align="center" type="sans" size="xs" color="on-surface-primary">
          {state === 'vote'
            ? `${subjectName?.full ?? 'Someone'} is sharing stories on Remento and you’ve been invited to help.`
            : `Invite more people to vote and encourage ${subjectName?.first ?? 'Someone'} to share new stories.`}
        </RMText>
      </DescriptionContent>

      <RMSpacer spacing="xl" direction="column" />

      <SelectionItems>
        {options.map((option, index) => (
          <Fragment key={option.id + state}>
            <PollVoteOption
              showResults={state === 'results'}
              question={option.question}
              selected={option.id == selectedOptionId}
              voteRatio={option.voteRatio}
              onSelect={() => onSelect(option)}
            />

            {state === 'results' && (
              <PollPromptInfo>
                <PersonNames>
                  {option.voted && <CheckVote icon={faCheck} color="var(--on-surface-primary)" />}
                  <RMText type="sans" size="xs" color="on-surface-primary">
                    {option.voteRatioDescription}
                  </RMText>
                </PersonNames>
                <RMText type="sans" size="xs" bold color="on-surface-primary">
                  {Math.round(option.voteRatio * 100)}%
                </RMText>
              </PollPromptInfo>
            )}

            {index < options.length - 1 && (
              <OptionDivider>
                {state === 'vote' && (
                  <RMText type="sans" size="xxs" bold color="on-surface-primary">
                    OR
                  </RMText>
                )}
              </OptionDivider>
            )}
          </Fragment>
        ))}
      </SelectionItems>

      <RMSpacer spacing="3xl" direction="column" />

      {state === 'vote' && (
        <>
          {containVotes ? (
            PollVotesList
          ) : (
            <RMText align="center" type="sans" size="xs" color="on-surface-primary">
              Be the first to vote!
            </RMText>
          )}
        </>
      )}

      {state === 'results' && (
        <RMText align="center" type="sans" size="xs" color="on-surface-primary">
          Invite more voters and add them to this project!
        </RMText>
      )}
    </SelectionContent>
  );
}
