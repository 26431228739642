import { styled } from '@linaria/react';

import { RMAvatarSharing } from '@/components/RMAvatarSharing';

export const StyledPollVoteAvatarList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const AvatarListRoot = styled(RMAvatarSharing.Root)`
  padding-left: 0;
`;
