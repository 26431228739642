import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ButtonGroup = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-lg);
  flex-direction: row;

  > button {
    width: 100px;
  }

  body[data-mobile='true'] & {
    flex-direction: column;

    > button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;
