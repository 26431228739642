import { useCallback } from 'react';

import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import { PollInviteByLink } from '../components/PollInviteByLink/PollInviteByLink';

interface PollInviteByLinkContainerProps {
  pollId: string;
  onClose: () => void;
}

export function PollInviteByLinkContainer({ pollId, onClose }: PollInviteByLinkContainerProps) {
  const { collaborationAnalyticsService, pollService } = useServices();

  const handleCopy = useCallback(async () => {
    try {
      collaborationAnalyticsService.onInvitedViaLink('poll-dialog');

      const urlPromise = pollService.getPollShareLink(pollId).then((shareLink) => shareLink.link);
      showShareSheetToast(await openShareSheet({ url: urlPromise }));
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [collaborationAnalyticsService, pollService, pollId]);

  return <PollInviteByLink onCopy={handleCopy} onDone={onClose} />;
}
